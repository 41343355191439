import {
  AppBar,
  Autocomplete,
  Button,
  CardContent,
  Chip,
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { MainContext } from "../../controllers/main"
import ImagePicker from "../global/imagePicker"
import { MarketplaceContext } from "../../controllers/marketplace"
import {
  GamificationProductType,
  ProductAvailability,
  ProductType,
  Stage,
} from "../../services/config/enum"
import {
  DialogTransition,
  capitalizeFirstCharacter,
  enumAsArray,
  hasDuplicateCurrency,
} from "../../services/utilities/utility"
import PriceCard from "./priceCard"
import {
  AddCircleRounded,
  CloseRounded,
  OpenInNewRounded,
} from "@mui/icons-material"
import { isDev } from "../../services/config/constants"
import Team from "../../models/team"
import { TeamsContext } from "../../controllers/teams"
import TeamsList from "../../views/team/teamsList"
import { LocationAutocompleteOption } from "../../services/config/interfaces"
import { CountriesContext } from "../../controllers/countries"

const ProductGeneralEdit = () => {
  const { showPadding } = useContext(MainContext)
  const {
    currentProduct,
    setCurrentProduct,
    editMode,
    handleError,
    setHandleError,
    projectIdError,
    setProjectIdError,
    limitPerUserError,
    setLimitPerUserError,
    sortingError,
    setSortingError,
    sponsoredByError,
    setSponsoredByError,
    availableAtLocationsError,
    setAvailableAtLocationsError,
    setPricesError,
    currenciesList,
  } = useContext(MarketplaceContext)
  const { resetTeamsFilters } = useContext(TeamsContext)
  const {
    regionsList,
    subRegionsList,
    countriesList,
    loading: countriesControllerLoading,
  } = useContext(CountriesContext)

  // check prices errors
  useEffect(() => {
    if (
      !currentProduct.prices.length ||
      currentProduct.prices.some(
        (price) => !price.amount || price.amount < 0
      ) ||
      hasDuplicateCurrency(currentProduct.prices)
    ) {
      setPricesError(true)
    } else {
      setPricesError(false)
    }
  }, [currentProduct])

  // change team dialog
  const [changeTeamDialogOpen, setChangeTeamDialogOpen] =
    useState<boolean>(false)
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null)

  return (
    <CardContent
      style={{
        height:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        maxHeight:
          showPadding === "yes" ? "calc(100vh - 200px)" : "calc(100vh - 183px)",
        overflowY: "scroll",
      }}
    >
      <Stack spacing={2}>
        <Typography variant="h6" className="card-title">
          Configuration
        </Typography>
        <Stack direction="row" gap={2}>
          <TextField
            fullWidth
            size="small"
            label="Handle"
            disabled={!editMode}
            value={currentProduct.handle}
            onChange={(e) => {
              setCurrentProduct({
                ...currentProduct,
                handle: e.target.value,
              })

              // errors check
              if (!e.target.value) {
                setHandleError(true)
              } else {
                setHandleError(false)
              }
            }}
            error={handleError}
            helperText={handleError ? "This field is required" : ""}
          />
          <TextField
            fullWidth
            size="small"
            label="Project ID"
            disabled={!editMode}
            value={currentProduct.projectId}
            onChange={(e) => {
              setCurrentProduct({
                ...currentProduct,
                projectId: e.target.value,
              })

              // errors check
              if (!e.target.value) {
                setProjectIdError(true)
              } else {
                setProjectIdError(false)
              }
            }}
            error={projectIdError}
            helperText={projectIdError ? "This field is required" : ""}
          />
        </Stack>
        <Stack direction="row" gap={2}>
          <FormControl fullWidth size="small" disabled={!editMode}>
            <InputLabel id="default-currency">Default currency</InputLabel>
            <Select
              labelId="default-currency"
              label="Default currency"
              value={currentProduct.defaultCurrency}
              onChange={(e) => {
                setCurrentProduct({
                  ...currentProduct,
                  defaultCurrency: e.target.value,
                })
              }}
            >
              {currenciesList.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth size="small" disabled={!editMode}>
            <InputLabel id="type">Type</InputLabel>
            <Select
              labelId="type"
              label="Type"
              value={currentProduct.type}
              onChange={(e) => {
                setCurrentProduct((current) => {
                  return {
                    ...current,
                    type: e.target.value as ProductType,
                    gamificationType:
                      e.target.value === ProductType.Gamification
                        ? GamificationProductType.life
                        : null,
                    availability:
                      e.target.value === ProductType.Gamification
                        ? ProductAvailability.Limited
                        : current.availability,
                  }
                })
              }}
            >
              {enumAsArray(ProductType).map((item: string, index) => (
                <MenuItem key={index} value={item}>
                  {capitalizeFirstCharacter(
                    item
                      .split(/(?<=[a-z])(?=[A-Z])/)
                      .join(" ")
                      .toLowerCase()
                  )}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {currentProduct.type === ProductType.Gamification && (
            <FormControl fullWidth size="small" disabled={!editMode}>
              <InputLabel id="gamification-type">Gamification type</InputLabel>
              <Select
                labelId="gamification-type"
                label="Gamification type"
                value={currentProduct.gamificationType}
                onChange={(e) => {
                  setCurrentProduct({
                    ...currentProduct,
                    gamificationType: e.target.value as GamificationProductType,
                  })
                }}
              >
                {enumAsArray(GamificationProductType).map(
                  (item: string, index) => (
                    <MenuItem key={index} value={item}>
                      {capitalizeFirstCharacter(item)}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          )}
          <FormControl
            fullWidth
            size="small"
            disabled={
              !editMode ||
              currentProduct.stage === Stage.PUBLISHED ||
              currentProduct.hasSibling
            }
          >
            <InputLabel id="availability">Availability</InputLabel>
            <Select
              labelId="availability"
              label="Availability"
              value={currentProduct.availability}
              onChange={(e) => {
                setCurrentProduct({
                  ...currentProduct,
                  availability: e.target.value as ProductAvailability,
                })
              }}
            >
              {enumAsArray(ProductAvailability).map((item: string, index) => (
                <MenuItem
                  key={index}
                  value={item}
                  disabled={
                    currentProduct.type === ProductType.Gamification &&
                    item === ProductAvailability.Unlimited
                  }
                >
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Stack direction="row" gap={2}>
          <TextField
            fullWidth
            type="number"
            size="small"
            label="Limit per user"
            disabled={!editMode}
            value={currentProduct.limitPerUser ?? ""}
            onChange={(e) => {
              setCurrentProduct({
                ...currentProduct,
                limitPerUser: e.target.value ? parseInt(e.target.value) : null,
              })

              // errors check
              if (
                !e.target.value ||
                (e.target.value && parseInt(e.target.value) < 1)
              ) {
                setLimitPerUserError(true)
              } else {
                setLimitPerUserError(false)
              }
            }}
            error={limitPerUserError}
            helperText={limitPerUserError ? "This field is required (> 0)" : ""}
          />
          <TextField
            fullWidth
            type="number"
            size="small"
            label="Sorting"
            disabled={!editMode}
            value={currentProduct.sorting ?? ""}
            onChange={(e) => {
              setCurrentProduct({
                ...currentProduct,
                sorting: e.target.value ? parseInt(e.target.value) : null,
              })

              // errors check
              if (
                !e.target.value ||
                (e.target.value && parseInt(e.target.value) < 0)
              ) {
                setSortingError(true)
              } else {
                setSortingError(false)
              }
            }}
            error={sortingError}
            helperText={sortingError ? "This field is required (>= 0)" : ""}
          />
        </Stack>
        <Stack direction="row" gap={2}>
          <TextField
            fullWidth
            size="small"
            label="Sponsor"
            disabled={!editMode}
            value={currentProduct.sponsoredBy}
            onChange={(e) => {
              setCurrentProduct({
                ...currentProduct,
                sponsoredBy: e.target.value,
              })

              // errors check
              if (!e.target.value) {
                setSponsoredByError(true)
              } else {
                setSponsoredByError(false)
              }
            }}
            error={sponsoredByError}
            helperText={sponsoredByError ? "This field is required" : ""}
          />
          <TextField
            disabled={!editMode}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <Stack direction="row" spacing={0}>
                  {currentProduct.team.id !== "team_default" && (
                    <Button
                      onClick={(e) => {
                        e.stopPropagation()
                        if (isDev) {
                          window.open(
                            window.location.protocol +
                              "//" +
                              window.location.host +
                              "/teams/" +
                              currentProduct.team.id
                          )
                        } else {
                          window.open(
                            window.location.protocol +
                              "//" +
                              window.location.host +
                              "/teams/" +
                              currentProduct.team.id
                          )
                        }
                      }}
                    >
                      <OpenInNewRounded
                        style={{
                          fontSize: 15,
                          opacity: 0.9,
                        }}
                      />
                    </Button>
                  )}
                  <Button
                    sx={{ paddingInline: 2 }}
                    onClick={() => {
                      setChangeTeamDialogOpen(true)
                    }}
                    disabled={!editMode}
                  >
                    {currentProduct.team.id !== "team_default"
                      ? "Change"
                      : "Select"}
                  </Button>
                  {currentProduct.team.id !== "team_default" && (
                    <Button
                      sx={{ paddingInline: 2 }}
                      onClick={() => {
                        setCurrentProduct({
                          ...currentProduct,
                          team: {
                            id: "team_default",
                            document: null,
                          },
                        })
                      }}
                      disabled={!editMode}
                    >
                      Remove
                    </Button>
                  )}
                </Stack>
              ),
            }}
            fullWidth
            label="Team"
            size="small"
            value={
              currentProduct.team.id !== "team_default"
                ? currentProduct.team.document.items.find(
                    (item) => item.isDefault
                  ).title
                : ""
            }
          />
        </Stack>
        <Typography variant="h6" className="card-title">
          Locations
        </Typography>
        <Autocomplete
          loading={countriesControllerLoading}
          disabled={!editMode}
          fullWidth
          blurOnSelect
          size="small"
          value={currentProduct.availableAtLocations ?? []}
          disablePortal
          multiple
          isOptionEqualToValue={(
            option: LocationAutocompleteOption,
            value: LocationAutocompleteOption
          ) => option.id === value.id}
          groupBy={(option: LocationAutocompleteOption) => {
            if (option.__typename !== "DefaultLocation") {
              return option.__typename
            }
          }}
          options={[
            {
              id: "defaultLocation",
              name: "Worldwide",
              __typename: "DefaultLocation",
            },
            ...regionsList.map((item) => {
              return {
                id: item.id,
                name: item.name,
                __typename: "Region",
              }
            }),
            ...subRegionsList.map((item) => {
              return {
                id: item.id,
                name: item.name,
                __typename: "SubRegion",
              }
            }),
            ...countriesList.map((item) => {
              return {
                id: item.id,
                name: item.name,
                __typename: "Country",
              }
            }),
          ]}
          getOptionLabel={(option: LocationAutocompleteOption) => {
            if (option.name === "defaultLocation") {
              return "Default"
            } else {
              return option.name
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Locations"
              error={availableAtLocationsError}
              helperText={
                availableAtLocationsError
                  ? "There must be at least one location"
                  : ""
              }
            />
          )}
          onChange={(e: any, newValues: LocationAutocompleteOption[]) => {
            setCurrentProduct((current) => {
              return {
                ...current,
                availableAtLocations:
                  newValues.length &&
                  newValues[newValues.length - 1].id === "defaultLocation"
                    ? [
                        {
                          id: "defaultLocation",
                          name: "defaultLocation",
                          __typename: "DefaultLocation",
                        },
                      ]
                    : newValues.filter((item) => item.id !== "defaultLocation"),
              }
            })

            // check for errors
            if (!newValues.length) {
              setAvailableAtLocationsError(true)
            } else {
              setAvailableAtLocationsError(false)
            }
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.name}
              </li>
            )
          }}
          renderTags={(tagValue, getTagProps) => {
            return tagValue.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                size="small"
                key={option.id}
                label={
                  option.name === "defaultLocation" ? "Worldwide" : option.name
                }
              />
            ))
          }}
        />
        <Typography variant="h6" className="card-title">
          Prices
        </Typography>
        {currentProduct.prices.length ? (
          <Stack>
            {currentProduct.prices.map((price, index) => (
              <PriceCard key={index} index={index} price={price} />
            ))}
          </Stack>
        ) : null}
        <Button
          fullWidth
          disabled={!editMode}
          endIcon={<AddCircleRounded />}
          variant="outlined"
          style={{
            maxHeight: 40,
            minHeight: 40,
          }}
          onClick={() => {
            currentProduct.prices.push({
              amount: 1,
              currency: currentProduct.defaultCurrency,
            })
            setCurrentProduct({ ...currentProduct })
          }}
        >
          Add price
        </Button>
        <Typography variant="h6" className="card-title">
          Image
        </Typography>
        <ImagePicker
          title="Image"
          pickedImage={currentProduct.image ?? ""}
          setPickedImage={(selectedImage: {
            url: string
            id: string
            description?: string
          }) => {
            currentProduct.image = selectedImage.url
            setCurrentProduct({ ...currentProduct })
          }}
          filters={{ category: "product" }}
          disableCategoryFilter
          height={200}
          disabled={!editMode}
        />
      </Stack>
      {/* change team dialog */}
      <Dialog
        fullScreen
        open={changeTeamDialogOpen}
        onClose={() => {
          setChangeTeamDialogOpen(false)
          setSelectedTeam(null)
          resetTeamsFilters()
        }}
        TransitionComponent={DialogTransition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setChangeTeamDialogOpen(false)
                setSelectedTeam(null)
                resetTeamsFilters()
              }}
              aria-label="close"
            >
              <CloseRounded />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Select Team
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => {
                currentProduct.team = selectedTeam
                setCurrentProduct({ ...currentProduct })

                setChangeTeamDialogOpen(false)
                setSelectedTeam(null)
                resetTeamsFilters()
              }}
              disabled={!selectedTeam}
            >
              Select
            </Button>
          </Toolbar>
        </AppBar>
        <Paper style={{ backgroundColor: "#f5f5f5" }}>
          <TeamsList
            forDialog
            selectedTeam={selectedTeam}
            setSelectedTeam={setSelectedTeam}
          />
        </Paper>
      </Dialog>
    </CardContent>
  )
}

export default ProductGeneralEdit
