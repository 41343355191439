import { LoadingButton } from "@mui/lab"
import {
  Button,
  CardActions,
  CardContent,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  Dialog,
  MenuItem,
  Select,
  AppBar,
  Toolbar,
  IconButton,
  Autocomplete,
  Chip,
} from "@mui/material"
import {
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import {
  DialogTransition,
  capitalizeFirstCharacter,
  enumAsArray,
  hasDuplicateCurrency,
  isValidURL,
  lowercaseFirstCharacter,
  stripHtml,
  testYtURL,
} from "../../services/utilities/utility"
import DialogTopBar from "../global/dialogTopBar"
import LoadingBackdrop from "../global/loadingBackdrop"
import { useNavigate } from "react-router-dom"
import { MainContext } from "../../controllers/main"
import ImagePicker from "../global/imagePicker"
import {
  BodySliceType,
  GamificationProductType,
  ProductAvailability,
  ProductType,
} from "../../services/config/enum"
import { LanguagesContext } from "../../controllers/languages"
import EmbedSlice from "../../models/bodySlices/embedSlice"
import ImageSlice from "../../models/bodySlices/imageSlice"
import QuoteSlice from "../../models/bodySlices/quoteSlice"
import TextSlice from "../../models/bodySlices/textSlice"
import { MarketplaceContext } from "../../controllers/marketplace"
import BlockEditor from "../blockEditor/blockEditor"
import Team from "../../models/team"
import { isDev } from "../../services/config/constants"
import {
  AddCircleRounded,
  CloseRounded,
  OpenInNewRounded,
} from "@mui/icons-material"
import { TeamsContext } from "../../controllers/teams"
import TeamsList from "../../views/team/teamsList"
import ProductPrice from "../../models/productPrice"
import PriceCardCreate from "./priceCardCreate"
import { LocationAutocompleteOption } from "../../services/config/interfaces"
import { CountriesContext } from "../../controllers/countries"

const CreateProductDialog = ({
  dialogOpen,
  setDialogOpen,
  handleDialogChange,
}: {
  dialogOpen: boolean
  setDialogOpen: Dispatch<SetStateAction<boolean>>
  handleDialogChange: MouseEventHandler<HTMLButtonElement>
}) => {
  const { setAnimation } = useContext(MainContext)
  const {
    loading,
    setLoading,
    createProduct,
    createProductDocument,
    currenciesListLoading,
    currenciesList,
  } = useContext(MarketplaceContext)
  const { languages, languagesForEpisodeTabs } = useContext(LanguagesContext)
  const { resetTeamsFilters } = useContext(TeamsContext)
  const {
    regionsList,
    subRegionsList,
    countriesList,
    loading: countriesControllerLoading,
  } = useContext(CountriesContext)
  const navigate = useNavigate()

  // prevent tab close
  const handleTabClose = useCallback((event: any) => {
    event.preventDefault()
    console.log("beforeunload event triggered")
    return (event.returnValue = "Are you sure you want to exit?")
  }, [])

  useEffect(() => {
    if (dialogOpen) {
      window.addEventListener("beforeunload", handleTabClose)
    } else {
      window.removeEventListener("beforeunload", handleTabClose)
    }
  }, [dialogOpen])

  // configuration
  const [handle, setHandle] = useState<string>("")
  const [projectId, setProjectId] = useState<string | null>(null)
  const [type, setType] = useState<ProductType>(ProductType.DigitalCode)
  const [gamificationType, setGamificationType] =
    useState<GamificationProductType>(GamificationProductType.life)
  const [availability, setAvailability] = useState<ProductAvailability>(
    ProductAvailability.Limited
  )
  const [defaultCurrency, setDefaultCurrency] = useState<string>("awc")
  const [limitPerUser, setLimitPerUser] = useState<string>("1")
  const [sorting, setSorting] = useState<string>("0")
  const [sponsoredBy, setSponsoredBy] = useState<string>("")
  const [team, setTeam] = useState<Team | null>(null)
  const [availableAtLocations, setAvailableAtLocations] = useState<
    LocationAutocompleteOption[]
  >([
    {
      id: "defaultLocation",
      name: "defaultLocation",
      __typename: "DefaultLocation",
    },
  ])

  // prices
  const [prices, setPrices] = useState<ProductPrice[]>([])

  // change team dialog
  const [changeTeamDialogOpen, setChangeTeamDialogOpen] =
    useState<boolean>(false)
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null)

  // image
  const [image, setImage] = useState<string>("")

  // document
  const [defaultTranslation, setDefaultTranslation] = useState<string>("")
  const [title, setTitle] = useState<string>("")
  const [body, setBody] = useState<
    (EmbedSlice | ImageSlice | QuoteSlice | TextSlice)[]
  >([])

  // check errors in body
  const [bodyError, setBodyError] = useState<boolean>(false)

  useEffect(() => {
    let errorInBody = false
    body.forEach((bodyItem) => {
      if (
        (bodyItem.sliceType === BodySliceType.BodyTextSlice &&
          !stripHtml(bodyItem.text).length) ||
        (bodyItem.sliceType === BodySliceType.BodyQuoteSlice &&
          !bodyItem.quoteText.length) ||
        (bodyItem.sliceType === BodySliceType.BodyImageSlice &&
          (!bodyItem.imgUrl.length || !isValidURL(bodyItem.imgUrl))) ||
        (bodyItem.sliceType === BodySliceType.BodyEmbedSlice &&
          (!bodyItem.embedUrl.length || !testYtURL(bodyItem.embedUrl)))
      ) {
        errorInBody = true
      }
    })
    if (errorInBody) {
      setBodyError(true)
    } else {
      setBodyError(false)
    }
  }, [body])

  // current slide and scrolling when changes
  const [currentSlide, setCurrentSlide] = useState<number>(0)

  useEffect(() => {
    setTimeout(() => {
      let slide = document.getElementById(currentSlide.toString())
      slide?.scrollIntoView({ behavior: "smooth" })
    }, 10)
  }, [currentSlide])

  // slides
  const slides = [
    {
      label: "Configuration",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Configuration
          </Typography>
          <Stack direction="row" gap={2}>
            <TextField
              fullWidth
              size="small"
              label="Handle"
              value={handle}
              onChange={(e) => {
                setHandle(e.target.value)
              }}
            />
            <Autocomplete
              fullWidth
              loading={currenciesListLoading}
              size="small"
              value={projectId}
              disablePortal
              options={
                currenciesList.length
                  ? currenciesList.map((currency) => currency.projectId)
                  : []
              }
              renderInput={(params) => (
                <TextField {...params} label="Project ID" />
              )}
              onChange={(e, newValue) => {
                setProjectId(newValue ? newValue.trim() : null)

                if (
                  currenciesList.some((item) => item.projectId === newValue)
                ) {
                  setDefaultCurrency(
                    currenciesList.find((item) => item.projectId === newValue)
                      .id
                  )
                }
              }}
              freeSolo
              autoSelect
            />
          </Stack>
          <Stack direction="row" gap={2}>
            <FormControl fullWidth size="small">
              <InputLabel id="default-currency">Default currency</InputLabel>
              <Select
                labelId="default-currency"
                label="Default currency"
                value={defaultCurrency}
                onChange={(e) => {
                  setDefaultCurrency(e.target.value)
                }}
              >
                {currenciesList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth size="small">
              <InputLabel id="type">Type</InputLabel>
              <Select
                labelId="type"
                label="Type"
                value={type}
                onChange={(e) => {
                  setType(e.target.value as ProductType)

                  if (e.target.value === ProductType.Gamification) {
                    setAvailability(ProductAvailability.Limited)
                  }
                }}
              >
                {enumAsArray(ProductType).map((item: string, index) => (
                  <MenuItem key={index} value={item}>
                    {capitalizeFirstCharacter(
                      item
                        .split(/(?<=[a-z])(?=[A-Z])/)
                        .join(" ")
                        .toLowerCase()
                    )}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {type === ProductType.Gamification && (
              <FormControl fullWidth size="small">
                <InputLabel id="gamification-type">
                  Gamification type
                </InputLabel>
                <Select
                  labelId="gamification-type"
                  label="Gamification type"
                  value={gamificationType}
                  onChange={(e) => {
                    setGamificationType(
                      e.target.value as GamificationProductType
                    )
                  }}
                >
                  {enumAsArray(GamificationProductType).map(
                    (item: string, index) => (
                      <MenuItem key={index} value={item}>
                        {capitalizeFirstCharacter(item)}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            )}
            <FormControl fullWidth size="small">
              <InputLabel id="availability">Availability</InputLabel>
              <Select
                labelId="availability"
                label="Availability"
                value={availability}
                onChange={(e) => {
                  setAvailability(e.target.value as ProductAvailability)
                }}
              >
                {enumAsArray(ProductAvailability).map((item: string, index) => (
                  <MenuItem
                    key={index}
                    value={item}
                    disabled={
                      type === ProductType.Gamification &&
                      item === ProductAvailability.Unlimited
                    }
                  >
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack direction="row" gap={2}>
            <TextField
              fullWidth
              type="number"
              size="small"
              label="Limit per user"
              value={limitPerUser}
              onChange={(e) => {
                setLimitPerUser(e.target.value)
              }}
            />
            <TextField
              fullWidth
              type="number"
              size="small"
              label="Sorting"
              value={sorting}
              onChange={(e) => {
                setSorting(e.target.value)
              }}
            />
            <TextField
              fullWidth
              size="small"
              label="Sponsor"
              value={sponsoredBy}
              onChange={(e) => {
                setSponsoredBy(e.target.value)
              }}
            />
          </Stack>
          <Stack direction="row" gap={2}>
            <TextField
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <Stack direction="row" spacing={0}>
                    {team ? (
                      <Button
                        onClick={(e) => {
                          e.stopPropagation()
                          if (isDev) {
                            window.open(
                              window.location.protocol +
                                "//" +
                                window.location.host +
                                "/teams/" +
                                team.id
                            )
                          } else {
                            window.open(
                              window.location.protocol +
                                "//" +
                                window.location.host +
                                "/teams/" +
                                team.id
                            )
                          }
                        }}
                      >
                        <OpenInNewRounded
                          style={{
                            fontSize: 15,
                            opacity: 0.9,
                          }}
                        />
                      </Button>
                    ) : null}
                    <Button
                      sx={{ paddingInline: 2 }}
                      onClick={() => {
                        setChangeTeamDialogOpen(true)
                      }}
                    >
                      {team ? "Change" : "Select"}
                    </Button>
                    {team ? (
                      <Button
                        sx={{ paddingInline: 2 }}
                        onClick={() => {
                          setTeam(null)
                        }}
                      >
                        Remove
                      </Button>
                    ) : null}
                  </Stack>
                ),
              }}
              fullWidth
              label="Team"
              size="small"
              value={
                team
                  ? team.document.items.find((item) => item.isDefault).title
                  : ""
              }
            />
            <Autocomplete
              loading={countriesControllerLoading}
              fullWidth
              blurOnSelect
              size="small"
              value={availableAtLocations ?? []}
              disablePortal
              multiple
              isOptionEqualToValue={(
                option: LocationAutocompleteOption,
                value: LocationAutocompleteOption
              ) => option.id === value.id}
              groupBy={(option: LocationAutocompleteOption) => {
                if (option.__typename !== "DefaultLocation") {
                  return option.__typename
                }
              }}
              limitTags={2}
              options={[
                {
                  id: "defaultLocation",
                  name: "Worldwide",
                  __typename: "DefaultLocation",
                },
                ...regionsList.map((item) => {
                  return {
                    id: item.id,
                    name: item.name,
                    __typename: "Region",
                  }
                }),
                ...subRegionsList.map((item) => {
                  return {
                    id: item.id,
                    name: item.name,
                    __typename: "SubRegion",
                  }
                }),
                ...countriesList.map((item) => {
                  return {
                    id: item.id,
                    name: item.name,
                    __typename: "Country",
                  }
                }),
              ]}
              getOptionLabel={(option: LocationAutocompleteOption) => {
                if (option.name === "defaultLocation") {
                  return "Default"
                } else {
                  return option.name
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Locations"
                  error={!availableAtLocations.length}
                  helperText={
                    !availableAtLocations.length
                      ? "There must be at least one location"
                      : ""
                  }
                />
              )}
              onChange={(e: any, newValues: LocationAutocompleteOption[]) => {
                setAvailableAtLocations(
                  newValues.length &&
                    newValues[newValues.length - 1].id === "defaultLocation"
                    ? [
                        {
                          id: "defaultLocation",
                          name: "defaultLocation",
                          __typename: "DefaultLocation",
                        },
                      ]
                    : newValues.filter((item) => item.id !== "defaultLocation")
                )
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                )
              }}
              renderTags={(tagValue, getTagProps) => {
                return tagValue.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    size="small"
                    key={option.id}
                    label={
                      option.name === "defaultLocation"
                        ? "Worldwide"
                        : option.name
                    }
                  />
                ))
              }}
            />
          </Stack>
        </Stack>
      ),
    },
    {
      label: "Prices",
      component: (
        <Stack gap={2} style={{ maxHeight: 290, overflowY: "scroll" }}>
          <Typography variant="h6" className="card-title">
            Prices
          </Typography>
          {prices.length ? (
            <Stack>
              {prices.map((price, index) => (
                <PriceCardCreate
                  key={index}
                  index={index}
                  price={price}
                  prices={prices}
                  setPrices={setPrices}
                />
              ))}
            </Stack>
          ) : null}
          <Button
            fullWidth
            endIcon={<AddCircleRounded />}
            variant="outlined"
            style={{
              maxHeight: 40,
              minHeight: 40,
            }}
            onClick={() => {
              prices.push({
                amount: 1,
                currency: defaultCurrency,
              })
              setPrices([...prices])
            }}
          >
            Add price
          </Button>
        </Stack>
      ),
    },
    {
      label: "Image",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Image
          </Typography>
          <ImagePicker
            title="Image"
            pickedImage={image}
            setPickedImage={(selectedImage: {
              url: string
              id: string
              description?: string
            }) => {
              setImage(selectedImage.url)
            }}
            filters={{ category: "product" }}
            disableCategoryFilter
            height={200}
          />
        </Stack>
      ),
    },
    {
      label: "Language",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Default language
          </Typography>
          <FormControl fullWidth size="small">
            <InputLabel id="language-select">Default language</InputLabel>
            <Select
              labelId="language-select"
              label="Default language"
              value={defaultTranslation}
              onChange={(e) => {
                setDefaultTranslation(e.target.value)
              }}
            >
              {languages.map((lang) => (
                <MenuItem key={lang} value={lang}>
                  {lang.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      ),
    },
    {
      label: "Details",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Details in{" "}
            {defaultTranslation.length
              ? languagesForEpisodeTabs.filter(
                  (item) => item.value === defaultTranslation
                )[0].label
              : ""}
          </Typography>
          <TextField
            size="small"
            label="Title"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value)
            }}
            onBlur={() => {
              if (title) {
                setTitle((current) => current.trim())
              }
            }}
          />
        </Stack>
      ),
    },
    {
      label: "Body",
      component: (
        <Stack spacing={2}>
          <Typography variant="h6" className="card-title">
            Body in{" "}
            {defaultTranslation.length
              ? languagesForEpisodeTabs.filter(
                  (item) => item.value === defaultTranslation
                )[0].label
              : ""}
          </Typography>
          <div
            style={{
              height: 260,
              maxHeight: 260,
              overflowY: "scroll",
            }}
          >
            <BlockEditor
              body={body}
              setBody={(
                body: (EmbedSlice | ImageSlice | QuoteSlice | TextSlice)[]
              ) => {
                setBody([...body])
              }}
            />
          </div>
        </Stack>
      ),
    },
  ]

  // reset dialog on close
  useEffect(() => {
    if (!dialogOpen) {
      setTimeout(() => {
        setHandle("")
        setProjectId(null)
        setType(ProductType.DigitalCode)
        setGamificationType(GamificationProductType.life)
        setAvailability(ProductAvailability.Limited)
        setDefaultCurrency("awc")
        setLimitPerUser("1")
        setSorting("0")
        setSponsoredBy("")
        setTeam(null)
        setAvailableAtLocations([
          {
            id: "defaultLocation",
            name: "defaultLocation",
            __typename: "DefaultLocation",
          },
        ])
        setPrices([])
        setImage("")
        setDefaultTranslation("")
        setTitle("")
        setBody([])
        setCurrentSlide(0)
      }, 100)
    }
  }, [dialogOpen])

  // discard dialog
  const [discardDialogOpen, setDiscardDialogOpen] = useState<boolean>(false)

  const handleDiscardDialogCancel = () => {
    setDiscardDialogOpen(false)
  }

  const handleDiscardDialogClose = (e: any) => {
    setDiscardDialogOpen(false)
    handleDialogChange(e)
  }

  // should show discard dialog
  const [showDiscardDialog, setShowDiscardDialog] = useState<boolean>(false)

  useEffect(() => {
    if (handle || projectId || sponsoredBy || image) {
      setShowDiscardDialog(true)
    } else {
      setShowDiscardDialog(false)
    }
  }, [handle, projectId, sponsoredBy, image])

  // create product
  const createNewProduct = async () => {
    setLoading(true)

    const input: {
      availability: ProductAvailability
      defaultCurrency: string
      handle: string
      image: string
      limitPerUser: number
      prices: ProductPrice[]
      projectId: string
      sorting: number
      sponsoredBy: string
      type: ProductType
      gamificationType?: GamificationProductType
      availableAtLocations: { id: string; type: string }[]
      teamId?: string
    } = {
      availability: availability,
      defaultCurrency: defaultCurrency,
      handle: handle,
      image: image,
      limitPerUser: parseInt(limitPerUser),
      prices: prices,
      projectId: projectId,
      sorting: parseInt(sorting),
      sponsoredBy: sponsoredBy,
      type: type,
      availableAtLocations: availableAtLocations.map((item) => {
        return {
          id: item.id,
          type: item.__typename,
        }
      }),
    }

    if (type === ProductType.Gamification) {
      input.gamificationType = gamificationType
    }
    if (team) {
      input.teamId = team.id
    }

    const result = await createProduct(input)

    if (result !== false) {
      const newProductId = result as string

      let bodyToSet = []
      const newBody = []
      body.forEach((bodyItem: any) => {
        const { sliceType, ...rest } = bodyItem
        newBody.push({
          [lowercaseFirstCharacter(bodyItem.sliceType)]: {
            ...rest,
          },
        })
      })
      bodyToSet = newBody

      const documentInput: {
        parentId: string
        type: string
        productDocumentItems: {
          lang: string
          title: string
          default: boolean
          body: object[]
        }[]
      } = {
        parentId: `#id#${newProductId}#stage#DRAFT`,
        type: "Product",
        productDocumentItems: [
          {
            lang: defaultTranslation,
            title: title,
            default: true,
            body: bodyToSet,
          },
        ],
      }

      await createProductDocument(documentInput)

      setDialogOpen(false)
      setAnimation(false)
      setTimeout(() => {
        navigate(`/marketplace/${newProductId}`)
      }, 250)
    } else {
      setLoading(false)
    }
  }

  return (
    <Dialog
      fullScreen
      open={dialogOpen}
      onClose={
        showDiscardDialog
          ? () => {
              setDiscardDialogOpen(true)
            }
          : handleDialogChange
      }
      TransitionComponent={DialogTransition}
    >
      <DialogTopBar
        handleDialogChange={
          showDiscardDialog
            ? () => {
                setDiscardDialogOpen(true)
              }
            : handleDialogChange
        }
        topBarText="Add Product"
      />
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          variant="outlined"
          style={{
            width: "70%",
            minWidth: 345,
            maxWidth: 850,
            height: 500,
            position: "relative",
          }}
        >
          <Typography
            variant="h4"
            textAlign="center"
            style={{ paddingTop: 16 }}
          >
            Add Product
          </Typography>
          <Stepper
            activeStep={currentSlide}
            style={{
              paddingTop: 24,
              paddingLeft: 8,
              paddingRight: 8,
              paddingBottom: 8,
              maxWidth: "100%",
              overflow: "overlay",
            }}
          >
            {slides.map((slide) => {
              return (
                <Step key={slide.label}>
                  <StepLabel>{slide.label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
          <div
            className="horizontal-scroll"
            style={{
              minWidth: 345,
              maxWidth: 850,
              height: "auto",
              display: "flex",
              flexDirection: "row",
              overflow: "hidden",
              scrollSnapType: "x mandatory",
            }}
          >
            {slides.map((slide, index) => (
              <div
                key={index}
                id={index.toString()}
                style={{ minWidth: "100%", scrollSnapAlign: "center" }}
              >
                <CardContent>{slide.component}</CardContent>
              </div>
            ))}
          </div>
          <CardActions
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              padding: 16,
              width: "100%",
            }}
          >
            <div style={{ width: "50%" }}>
              <Button
                variant="outlined"
                onMouseDown={(e: any) => {
                  if (!slides[currentSlide - 1]) {
                    if (showDiscardDialog) {
                      setDiscardDialogOpen(true)
                    } else {
                      handleDialogChange(e)
                    }
                  } else {
                    setCurrentSlide(currentSlide - 1)
                  }
                }}
              >
                {!slides[currentSlide - 1] ? "Close" : "Previous"}
              </Button>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <LoadingButton
                variant="contained"
                disabled={
                  (currentSlide === 0 && !handle) ||
                  (currentSlide === 0 && !projectId) ||
                  (currentSlide === 0 && !limitPerUser) ||
                  (currentSlide === 0 &&
                    limitPerUser &&
                    parseInt(limitPerUser) <= 0) ||
                  (currentSlide === 0 && !sorting) ||
                  (currentSlide === 0 && sorting && parseInt(sorting) < 0) ||
                  (currentSlide === 0 && !sponsoredBy) ||
                  (currentSlide === 0 && !availableAtLocations.length) ||
                  (currentSlide === 1 && !slides.length) ||
                  (currentSlide === 1 && hasDuplicateCurrency(prices)) ||
                  (currentSlide === 1 &&
                    prices.some(
                      (price) => !price.amount || price.amount < 0
                    )) ||
                  (currentSlide === 2 && !image) ||
                  (currentSlide === 3 && !defaultTranslation) ||
                  (currentSlide === 4 && !title) ||
                  (currentSlide === 5 && bodyError)
                }
                onMouseDown={
                  !slides[currentSlide + 1]
                    ? () => {
                        createNewProduct()
                      }
                    : () => {
                        setCurrentSlide(currentSlide + 1)
                      }
                }
              >
                {!slides[currentSlide + 1] ? "Save" : "Next"}
              </LoadingButton>
            </div>
          </CardActions>
        </Paper>
      </div>
      <Dialog
        open={discardDialogOpen}
        onClose={handleDiscardDialogCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to close this window?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            All entered data will be lost
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDiscardDialogCancel}>Cancel</Button>
          <Button onClick={handleDiscardDialogClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingBackdrop open={loading} />
      {/* change team dialog */}
      <Dialog
        fullScreen
        open={changeTeamDialogOpen}
        onClose={() => {
          setChangeTeamDialogOpen(false)
          setSelectedTeam(null)
          resetTeamsFilters()
        }}
        TransitionComponent={DialogTransition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setChangeTeamDialogOpen(false)
                setSelectedTeam(null)
                resetTeamsFilters()
              }}
              aria-label="close"
            >
              <CloseRounded />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Select Team
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => {
                setTeam(selectedTeam)

                setChangeTeamDialogOpen(false)
                setSelectedTeam(null)
                resetTeamsFilters()
              }}
              disabled={!selectedTeam}
            >
              Select
            </Button>
          </Toolbar>
        </AppBar>
        <Paper style={{ backgroundColor: "#f5f5f5" }}>
          <TeamsList
            forDialog
            selectedTeam={selectedTeam}
            setSelectedTeam={setSelectedTeam}
          />
        </Paper>
      </Dialog>
    </Dialog>
  )
}

export default CreateProductDialog
