import { useLazyQuery, useMutation } from "@apollo/client"
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import News from "../models/news"
import NewsDocument from "../models/newsDocument"
import {
  deleteNews as deleteSingleNews,
  upsertDocument,
  upsertNews,
} from "../services/graphql/mutations"
import { allNewsList, news, searchNews } from "../services/graphql/queries"
import { deepCopy, logger, Status } from "../services/utilities/utility"
import { MainContext } from "./main"
import { lowercaseFirstCharacter } from "../services/utilities/utility"

interface AutocompleteOption {
  label: string
  id: string
}

interface NewsContextInterface {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  updatingList: boolean
  setUpdatingList: Dispatch<SetStateAction<boolean>>
  editMode: boolean
  setEditMode: Dispatch<SetStateAction<boolean>>
  newsList: News[]
  getNewsList: (withLoading?: boolean) => void
  doneChanges: boolean
  cancelChanges: () => void
  currentNews: News
  setCurrentNews: Dispatch<SetStateAction<News>>
  getCurrentNews: (newsId: string) => Promise<boolean>
  preChangesCurrentNews: News
  addTranslation: (translationToAdd: string) => void
  upsertNewsParent: () => Promise<boolean>
  hasError: boolean
  startsAtError: boolean
  setStartsAtError: Dispatch<SetStateAction<boolean>>
  endsAtError: boolean
  setEndsAtError: Dispatch<SetStateAction<boolean>>
  pointsError: boolean
  setPointsError: Dispatch<SetStateAction<boolean>>
  translationsErrors: { lang: string; hasErrors: boolean }[]
  setTranslationsErrors: Dispatch<
    SetStateAction<{ lang: string; hasErrors: boolean }[]>
  >
  upsertNewsDocument: () => Promise<boolean>
  copyDetailsFromDefault: (itemToCopyToIndex: number) => void
  copyBodyFromDefault: (itemToCopyToIndex: number) => void
  createNews: (input: {
    startsAt: string
    endsAt: string
    teamId: string
    points: number | null
  }) => Promise<News | boolean>
  createNewsDocument: (input: {
    parentId: string
    type: string
    newsDocumentItems: {
      body: object[]
      default: boolean
      image: string
      lang: string
      title: string
      redirectUrl?: string
      tagline?: string
    }[]
  }) => Promise<boolean | { items: NewsDocument[]; parentId: string }>
  newsListNextToken: string | null
  loadMoreNews: () => Promise<boolean>
  deleteNews: (newsToDeleteId: string) => Promise<boolean>
  searchLang: AutocompleteOption | null
  setSearchLang: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchTeam: AutocompleteOption | null
  setSearchTeam: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchTitle: string
  setSearchTitle: Dispatch<SetStateAction<string>>
  hasSearched: boolean
  searchNewsList: (
    withLoading?: boolean,
    withNextToken?: boolean
  ) => Promise<boolean>
}

const NewsContext = createContext<NewsContextInterface>({
  loading: true,
  setLoading: () => {},
  updatingList: false,
  setUpdatingList: () => {},
  editMode: false,
  setEditMode: () => {},
  newsList: [],
  getNewsList: () => {},
  doneChanges: false,
  cancelChanges: () => {},
  currentNews: new News(),
  setCurrentNews: () => {},
  getCurrentNews: async () => true,
  preChangesCurrentNews: new News(),
  addTranslation: () => {},
  upsertNewsParent: async () => true,
  hasError: false,
  startsAtError: false,
  setStartsAtError: () => {},
  endsAtError: false,
  setEndsAtError: () => {},
  pointsError: false,
  setPointsError: () => {},
  translationsErrors: [],
  setTranslationsErrors: () => {},
  upsertNewsDocument: async () => true,
  copyDetailsFromDefault: () => {},
  copyBodyFromDefault: () => {},
  createNews: async () => new News(),
  createNewsDocument: async () => true,
  newsListNextToken: null,
  loadMoreNews: async () => true,
  deleteNews: async () => true,
  searchLang: null,
  setSearchLang: () => {},
  searchTeam: null,
  setSearchTeam: () => {},
  searchTitle: "",
  setSearchTitle: () => {},
  hasSearched: false,
  searchNewsList: async () => true,
})

const NewsController = ({ children }: { children: ReactNode }) => {
  const { setError, setErrorMessage, panelStatus, setPanelStatus } =
    useContext(MainContext)

  // states
  const [loading, setLoading] = useState<boolean>(true) // loading
  const [updatingList, setUpdatingList] = useState<boolean>(false) // to show loader
  const [editMode, setEditMode] = useState<boolean>(true) // if all page should be disabled or not
  const [newsList, setNewsList] = useState<News[]>([]) // list of all news
  const [doneChanges, setDoneChanges] = useState<boolean>(false) // if user has done changes
  const [currentNews, setCurrentNews] = useState<News>(new News()) // current news
  const [preChangesCurrentNews, setPreChangesCurrentNews] = useState<News>(
    new News()
  ) // fetched current news
  const [newsListNextToken, setNewsListNextToken] = useState<string | null>(
    null
  ) // next token for news list
  const [hasSearched, setHasSearched] = useState<boolean>(false) // if user has searched or not

  // search states
  const [searchLang, setSearchLang] = useState<AutocompleteOption | null>(null)
  const [searchTeam, setSearchTeam] = useState<AutocompleteOption | null>(null)
  const [searchTitle, setSearchTitle] = useState<string>("")

  // errors for news edit and create
  const [startsAtError, setStartsAtError] = useState<boolean>(false) // error for news start date
  const [endsAtError, setEndsAtError] = useState<boolean>(false) // error for news end date
  const [pointsError, setPointsError] = useState<boolean>(false) // error for news points
  const [translationsErrors, setTranslationsErrors] = useState<
    { lang: string; hasErrors: boolean }[]
  >([]) // errors array for translations
  const hasError: boolean =
    startsAtError ||
    endsAtError ||
    pointsError ||
    translationsErrors.filter((item) => item.hasErrors).length !== 0 // if there are errors or not

  // reset all errors
  const resetErrors = () => {
    setStartsAtError(false)
    setEndsAtError(false)
    setPointsError(false)
    const newTranslationsErrors: { lang: string; hasErrors: boolean }[] = []
    currentNews.document.items.forEach((item) => {
      newTranslationsErrors.push({ lang: item.lang, hasErrors: false })
    })
    setTranslationsErrors(newTranslationsErrors)
  }

  // queries
  const [newsListQuery] = useLazyQuery(allNewsList)
  const [newsQuery] = useLazyQuery(news)
  const [searchNewsQuery] = useLazyQuery(searchNews)

  // mutations
  const [upsertNewsMutation] = useMutation(upsertNews)
  const [upsertDocumentMutation] = useMutation(upsertDocument)
  const [deleteNewsMutation] = useMutation(deleteSingleNews)

  // get news list
  const getNewsList = useCallback(
    async (withLoading: boolean = true) => {
      if (withLoading) {
        setLoading(true)
        panelStatus.filter((item) => item.name === "News")[0].loading = true
        setPanelStatus([...panelStatus])
      }

      try {
        logger(Status.Api, "QUERY newsAllList", allNewsList)
        const { data } = await newsListQuery({
          variables: {
            input: {
              teamId: "team_default",
              limit: Math.round(window.innerHeight / 74) + 10,
            },
          },
          fetchPolicy: "no-cache",
        })
        logger(Status.Info, "news list", data.newsAllList.items)

        setNewsList(data.newsAllList.items)
        setNewsListNextToken(data.newsAllList.nextToken)

        setLoading(false)
        setUpdatingList(false)
        panelStatus.filter((item) => item.name === "News")[0].status = "success"
        panelStatus.filter((item) => item.name === "News")[0].loading = false
        setPanelStatus([...panelStatus])
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `newsAllList`, e.message)
        }
        setLoading(false)
        setUpdatingList(false)
        panelStatus.filter((item) => item.name === "News")[0].status = "error"
        panelStatus.filter((item) => item.name === "News")[0].loading = false
        setPanelStatus([...panelStatus])
      }
    },
    [setError, setErrorMessage]
  )

  // search news
  const searchNewsList = async (withLoading = true, withNextToken = false) => {
    if (withLoading) {
      setUpdatingList(true)
    }

    try {
      const input: {
        limit: number
        teamId?: string
        lang?: string
        text?: string
        nextToken?: string
      } = {
        limit: Math.round(window.innerHeight / 74) + 10,
        teamId: searchTeam ? searchTeam.id : null,
        lang: searchLang ? searchLang.id : null,
        text: searchTitle,
      }

      if (hasSearched && withNextToken) {
        input.nextToken = newsListNextToken
      }

      logger(Status.Api, "QUERY newsSearch", searchNews)
      const { data } = await searchNewsQuery({
        variables: {
          input: input,
        },
        fetchPolicy: "no-cache",
      })

      if (input.nextToken) {
        logger(Status.Info, "news list", [
          ...newsList,
          ...data.newsSearch.items,
        ])

        setNewsList([...newsList, ...data.newsSearch.items])
        setNewsListNextToken(data.newsSearch.nextToken)
      } else {
        logger(Status.Info, "news list", data.newsSearch.items)

        setNewsList(data.newsSearch.items)
        setNewsListNextToken(data.newsSearch.nextToken)
      }

      setHasSearched(true)
      setUpdatingList(false)
      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `newsSearch`, e.message)
      }
      setUpdatingList(false)
      return false
    }
  }

  // load more news
  const loadMoreNews = async () => {
    try {
      logger(Status.Api, "QUERY newsAllList", allNewsList)
      const { data } = await newsListQuery({
        variables: {
          input: { teamId: "team_default", nextToken: newsListNextToken },
        },
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, "news list", [...newsList, ...data.newsAllList.items])

      setNewsList([...newsList, ...data.newsAllList.items])
      setNewsListNextToken(data.newsAllList.nextToken)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `newsAllList`, e.message)
      }
      return false
    }
  }

  // get current news
  const getCurrentNews = useCallback(
    async (newsId: string) => {
      try {
        logger(Status.Api, "QUERY newsGet", news)
        const { data } = await newsQuery({
          variables: { input: { id: newsId, lang: "all" } },
          fetchPolicy: "no-cache",
        })
        logger(Status.Info, `news ${newsId}`, data.newsGet)

        setCurrentNews(data.newsGet)
        setPreChangesCurrentNews(deepCopy(data.newsGet))
        data.newsGet.document.items.forEach((item: NewsDocument) => {
          translationsErrors.push({ lang: item.lang, hasErrors: false })
        })
        setTranslationsErrors([...translationsErrors])

        return true
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `newsGet`, e.message)
        }
        return false
      }
    },
    [setError, setErrorMessage]
  )

  // upsert news parent
  const upsertNewsParent = async () => {
    try {
      const input = {
        id: currentNews.id,
        teamId: currentNews.teamId,
        startsAt: currentNews.startsAt,
        endsAt: currentNews.endsAt,
        points: currentNews.points,
      }

      logger(Status.Api, "MUTATION newsUpsert", upsertNews)
      const { data } = await upsertNewsMutation({
        variables: { input: input },
      })
      logger(
        Status.Info,
        `news ${data.newsUpsert.id} upserted`,
        data.newsUpsert
      )

      const currentNewsCopy: News = deepCopy(currentNews)
      currentNewsCopy.id = data.newsUpsert.id
      currentNewsCopy.startsAt = data.newsUpsert.startsAt
      currentNewsCopy.endsAt = data.newsUpsert.endsAt
      currentNewsCopy.updatedAt = data.newsUpsert.updatedAt
      currentNewsCopy.teamId = data.newsUpsert.teamId
      currentNewsCopy.points = data.newsUpsert.points
      currentNewsCopy.document.parentId = data.newsUpsert.id
      setCurrentNews(currentNewsCopy)
      setPreChangesCurrentNews(deepCopy(currentNewsCopy))

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `newsUpsert`, e.message)
      }
      return false
    }
  }

  // upsert news document
  const upsertNewsDocument = async () => {
    try {
      // parse data for inpout
      const currentNewsCopy = deepCopy(currentNews)
      currentNewsCopy.document.items.forEach((item: any) => {
        if (item.redirectUrl) {
          item.body = []
        } else {
          const newBody = []
          item.body.forEach((bodyItem: any) => {
            const { sliceType, __typename, ...rest } = bodyItem
            newBody.push({
              [lowercaseFirstCharacter(bodyItem.sliceType)]: {
                ...rest,
              },
            })
          })
          item.body = newBody
        }
        item.default = item.isDefault
        delete item.isDefault
        delete item.type
        delete item.updatedAt
        delete item.__typename
      })

      const input = {
        newsDocumentItems: currentNewsCopy.document.items,
        parentId: currentNewsCopy.document.parentId,
        type: "News",
      }

      logger(Status.Api, "MUTATION documentUpsert", upsertDocument)
      const { data } = await upsertDocumentMutation({
        variables: { input: input },
      })
      logger(
        Status.Info,
        `news ${data.documentUpsert.parentId} document upserted`,
        data.documentUpsert
      )

      const currentNewsSecondCopy: News = deepCopy(currentNews)
      currentNewsSecondCopy.document = data.documentUpsert
      setCurrentNews(currentNewsSecondCopy)
      setPreChangesCurrentNews(deepCopy(currentNewsSecondCopy))

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `documentUpsert`, e.message)
      }
      return false
    }
  }

  // create news
  const createNews = async (input: {
    startsAt: string
    endsAt: string
    teamId: string
    points: number | null
  }) => {
    try {
      logger(Status.Api, "MUTATION newsUpsert", upsertNews)
      const { data } = await upsertNewsMutation({
        variables: { input: input },
      })
      logger(Status.Info, `news created`, data.newsUpsert)

      return data.newsUpsert
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `newsUpsert`, e.message)
      }
      return false
    }
  }

  // create news document
  const createNewsDocument = async (input: {
    parentId: string
    type: string
    newsDocumentItems: {
      body: object[]
      default: boolean
      image: string
      lang: string
      title: string
      redirectUrl?: string
      tagline?: string
    }[]
  }) => {
    try {
      logger(Status.Api, "MUTATION documentUpsert", upsertDocument)
      const { data } = await upsertDocumentMutation({
        variables: { input: input },
      })
      logger(Status.Info, `document created`, data.documentUpsert)

      return data.documentUpsert
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `documentUpsert`, e.message)
      }
      return false
    }
  }

  // delete news
  const deleteNews = async (newsToDeleteId: string) => {
    try {
      logger(Status.Api, "MUTATION newsDelete", deleteSingleNews)
      const { data } = await deleteNewsMutation({
        variables: { input: { id: newsToDeleteId } },
      })
      logger(Status.Info, `news ${data.newsDelete.id} deleted`)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `newsDelete`, e.message)
      }
      return false
    }
  }

  // add translation
  const addTranslation = (translationToAdd: string) => {
    const documentToAdd: NewsDocument = {
      body: [],
      image: "",
      isDefault: false,
      lang: translationToAdd,
      redirectUrl: null,
      tagline: null,
      title: "Title",
      type: "News",
    }

    translationsErrors.push({
      lang: translationToAdd,
      hasErrors: false,
    })
    setTranslationsErrors([...translationsErrors])

    currentNews.document.items.push(documentToAdd)
    setCurrentNews({ ...currentNews })
  }

  // copy details from default translation
  const copyDetailsFromDefault = (itemToCopyToIndex: number) => {
    const defaultItem = currentNews.document.items.filter(
      (item) => item.isDefault
    )[0]

    currentNews.document.items[itemToCopyToIndex].title = defaultItem.title
    currentNews.document.items[itemToCopyToIndex].tagline = defaultItem.tagline
    currentNews.document.items[itemToCopyToIndex].redirectUrl =
      defaultItem.redirectUrl
    currentNews.document.items[itemToCopyToIndex].image = defaultItem.image

    setCurrentNews({ ...currentNews })
  }

  // copy body from default translation
  const copyBodyFromDefault = (itemToCopyToIndex: number) => {
    const defaultItem = currentNews.document.items.filter(
      (item) => item.isDefault
    )[0]

    currentNews.document.items[itemToCopyToIndex].body = defaultItem.body

    setCurrentNews({ ...currentNews })
  }

  // cancel changes
  const cancelChanges = () => {
    setCurrentNews(deepCopy(preChangesCurrentNews))
    resetErrors()
  }

  // check if user has done changes
  useEffect(() => {
    if (JSON.stringify(currentNews) !== JSON.stringify(preChangesCurrentNews)) {
      setDoneChanges(true)
    } else {
      setDoneChanges(false)
    }
  }, [currentNews])

  // add update function to panel status
  useEffect(() => {
    panelStatus.filter((item) => item.name === "News")[0].updateFunction =
      getNewsList
  }, [])

  return (
    <NewsContext.Provider
      value={{
        loading,
        setLoading,
        updatingList,
        setUpdatingList,
        editMode,
        setEditMode,
        newsList,
        getNewsList,
        doneChanges,
        cancelChanges,
        currentNews,
        setCurrentNews,
        getCurrentNews,
        preChangesCurrentNews,
        addTranslation,
        upsertNewsParent,
        hasError,
        startsAtError,
        setStartsAtError,
        endsAtError,
        setEndsAtError,
        pointsError,
        setPointsError,
        translationsErrors,
        setTranslationsErrors,
        upsertNewsDocument,
        copyDetailsFromDefault,
        copyBodyFromDefault,
        createNews,
        createNewsDocument,
        newsListNextToken,
        loadMoreNews,
        deleteNews,
        searchLang,
        setSearchLang,
        searchTeam,
        setSearchTeam,
        searchTitle,
        setSearchTitle,
        hasSearched,
        searchNewsList,
      }}
    >
      {children}
    </NewsContext.Provider>
  )
}

export { NewsController, NewsContext }
