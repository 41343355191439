import { useLazyQuery, useMutation } from "@apollo/client"
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import {
  challengeAutojoin,
  createChallenge,
  deleteChallenge as deleteSingleChallenge,
  updateChallenge,
  upsertDocumentChallenge,
} from "../services/graphql/mutations"
import {
  challenge,
  searchChallenges,
  listChallenges,
  challengeUserLeaderboardList,
  challengeGroupLeaderboardList,
  challengeIntraGroupLeaderboardList,
} from "../services/graphql/queries"
import { deepCopy, logger, Status } from "../services/utilities/utility"
import { MainContext } from "./main"
import { lowercaseFirstCharacter } from "../services/utilities/utility"
import { ChallengeType, ChallengeVersionType } from "../services/config/enum"
import Challenge from "../models/challenge"
import ChallengesDocument from "../models/challengesDocument"
import UsersLeaderboardItem from "../models/usersLeaderboardItem"
import GroupsLeaderboardItem from "../models/groupsLeaderboardItem"
import { AutocompleteOption } from "../services/config/interfaces"

interface ChallengesContextInterface {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  updatingList: boolean
  setUpdatingList: Dispatch<SetStateAction<boolean>>
  editMode: boolean
  setEditMode: Dispatch<SetStateAction<boolean>>
  challengesList: Challenge[]
  getChallengesList: (withLoading?: boolean) => void
  doneChanges: boolean
  cancelChanges: () => void
  currentChallenge: Challenge
  setCurrentChallenge: Dispatch<SetStateAction<Challenge>>
  getCurrentChallenge: (challengeId: string) => Promise<boolean>
  preChangesCurrentChallenge: Challenge
  addTranslation: (translationToAdd: string) => void
  upsertChallengeParent: () => Promise<boolean>
  hasError: boolean
  translationsErrors: { lang: string; hasErrors: boolean }[]
  setTranslationsErrors: Dispatch<
    SetStateAction<{ lang: string; hasErrors: boolean }[]>
  >
  upsertChallengeDocument: () => Promise<boolean>
  copyDetailsFromDefault: (itemToCopyToIndex: number) => void
  copyBodyFromDefault: (itemToCopyToIndex: number) => void
  copyPrizesFromDefault: (itemToCopyToIndex: number) => void
  createChallengeParent: (input: {
    endsAt: string
    metricId: string
    name: string
    startsAt: string
    targetAmount: number
    leaderboardGroupType: "average" | "sum"
    leaderboardMinMembers: number
    type: ChallengeType
    uid: string
    teamId?: string
    personalTargetAmount?: number
    personalTargetPoints?: number
    privacyPolicy?: string
    versionType?: ChallengeVersionType
  }) => Promise<Challenge | boolean>
  createChallengeDocument: (input: {
    parentId: string
    type: string
    challengeDocumentItems: {
      lang: string
      title: string
      image: string
      default: boolean
      body: object[]
      prizes: object[]
      sponsor?: string
      subtitle?: string
    }[]
  }) => Promise<{ items: ChallengesDocument[]; parentId: string } | boolean>
  challengesListNextToken: string | null
  loadMoreChallenges: () => Promise<boolean>
  hasSearched: boolean
  setHasSearched: Dispatch<SetStateAction<boolean | null>>
  searchMetric: AutocompleteOption | null
  setSearchMetric: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchTeam: AutocompleteOption | null
  setSearchTeam: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchTime: AutocompleteOption | null
  setSearchTime: Dispatch<SetStateAction<AutocompleteOption | null>>
  searchTitle: string
  setSearchTitle: Dispatch<SetStateAction<string>>
  searchChallengesList: (
    withLoading?: boolean,
    withNextToken?: boolean
  ) => Promise<boolean>
  resetFilters: () => void
  startsAtError: boolean
  setStartsAtError: Dispatch<SetStateAction<boolean>>
  endsAtError: boolean
  setEndsAtError: Dispatch<SetStateAction<boolean>>
  titleError: boolean
  setTitleError: Dispatch<SetStateAction<boolean>>
  metricError: boolean
  setMetricError: Dispatch<SetStateAction<boolean>>
  targetAmountError: boolean
  setTargetAmountError: Dispatch<SetStateAction<boolean>>
  personalTargetAmountError: boolean
  setPersonalTargetAmountError: Dispatch<SetStateAction<boolean>>
  personalTargetPointsError: boolean
  setPersonalTargetPointsError: Dispatch<SetStateAction<boolean>>
  teamError: boolean
  setTeamError: Dispatch<SetStateAction<boolean>>
  leaderboardMinMembersError: boolean
  setLeaderboardMinMembersError: Dispatch<SetStateAction<boolean>>
  privacyPolicyError: boolean
  setPrivacyPolicyError: Dispatch<SetStateAction<boolean>>
  deleteChallenge: (challengeToDeleteId: string) => Promise<boolean>
  autojoinTeam: () => Promise<boolean>
  currentUsersLeaderboard: UsersLeaderboardItem[]
  setCurrentUsersLeaderboard: Dispatch<SetStateAction<UsersLeaderboardItem[]>>
  currentUsersLeaderboardNextToken: string | null
  setCurrentUsersLeaderboardNextToken: Dispatch<SetStateAction<string | null>>
  getUsersLeaderboard: (
    challengeId: string,
    withNextToken?: boolean
  ) => Promise<boolean>
  currentGroupsLeaderboard: GroupsLeaderboardItem[]
  setCurrentGroupsLeaderboard: Dispatch<SetStateAction<GroupsLeaderboardItem[]>>
  currentGroupsLeaderboardNextToken: string | null
  setCurrentGroupsLeaderboardNextToken: Dispatch<SetStateAction<string | null>>
  getGroupsLeaderboard: (
    challengeId: string,
    leaderboardGroupType: "sum" | "average",
    withNextToken?: boolean
  ) => Promise<boolean>
  currentGroupLeaderboard: UsersLeaderboardItem[]
  setCurrentGroupLeaderboard: Dispatch<SetStateAction<UsersLeaderboardItem[]>>
  currentGroupLeaderboardNextToken: string | null
  setCurrentGroupLeaderboardNextToken: Dispatch<SetStateAction<string | null>>
  getGroupLeaderboard: (
    groupId: string,
    challengeId: string,
    withNextToken?: boolean
  ) => Promise<boolean>
  getAllLeaderboard: (
    challengeId: string,
    withEmails: boolean,
    leaderboardType: "users" | "groups" | "group",
    groupId?: string,
    leaderboardGroupType?: "sum" | "average"
  ) => Promise<string[][]>
}

const ChallengesContext = createContext<ChallengesContextInterface>({
  loading: true,
  setLoading: () => {},
  updatingList: false,
  setUpdatingList: () => {},
  editMode: false,
  setEditMode: () => {},
  challengesList: [],
  getChallengesList: () => {},
  doneChanges: false,
  cancelChanges: () => {},
  currentChallenge: new Challenge(),
  setCurrentChallenge: () => {},
  getCurrentChallenge: async () => true,
  preChangesCurrentChallenge: new Challenge(),
  addTranslation: () => {},
  upsertChallengeParent: async () => true,
  hasError: false,
  translationsErrors: [],
  setTranslationsErrors: () => {},
  upsertChallengeDocument: async () => true,
  copyDetailsFromDefault: () => {},
  copyBodyFromDefault: () => {},
  copyPrizesFromDefault: () => {},
  createChallengeParent: async () => true,
  createChallengeDocument: async () => true,
  challengesListNextToken: null,
  loadMoreChallenges: async () => true,
  hasSearched: false,
  setHasSearched: () => {},
  searchMetric: null,
  setSearchMetric: () => {},
  searchTeam: null,
  setSearchTeam: () => {},
  searchTime: null,
  setSearchTime: () => {},
  searchTitle: "",
  setSearchTitle: () => {},
  searchChallengesList: async () => true,
  resetFilters: () => {},
  startsAtError: false,
  setStartsAtError: () => {},
  endsAtError: false,
  setEndsAtError: () => {},
  titleError: false,
  setTitleError: () => {},
  metricError: false,
  setMetricError: () => {},
  targetAmountError: false,
  setTargetAmountError: () => {},
  personalTargetAmountError: false,
  setPersonalTargetAmountError: () => {},
  personalTargetPointsError: false,
  setPersonalTargetPointsError: () => {},
  teamError: false,
  setTeamError: () => {},
  leaderboardMinMembersError: false,
  setLeaderboardMinMembersError: () => {},
  privacyPolicyError: false,
  setPrivacyPolicyError: () => {},
  deleteChallenge: async () => true,
  autojoinTeam: async () => true,
  currentUsersLeaderboard: [],
  setCurrentUsersLeaderboard: () => {},
  currentUsersLeaderboardNextToken: null,
  setCurrentUsersLeaderboardNextToken: () => {},
  getUsersLeaderboard: async () => true,
  currentGroupsLeaderboard: [],
  setCurrentGroupsLeaderboard: () => {},
  currentGroupsLeaderboardNextToken: null,
  setCurrentGroupsLeaderboardNextToken: () => {},
  getGroupsLeaderboard: async () => true,
  currentGroupLeaderboard: [],
  setCurrentGroupLeaderboard: () => {},
  currentGroupLeaderboardNextToken: null,
  setCurrentGroupLeaderboardNextToken: () => {},
  getGroupLeaderboard: async () => true,
  getAllLeaderboard: async () => [],
})

const ChallengesController = ({ children }: { children: ReactNode }) => {
  const { setError, setErrorMessage, panelStatus, setPanelStatus } =
    useContext(MainContext)

  // states
  const [loading, setLoading] = useState<boolean>(true) // loading
  const [updatingList, setUpdatingList] = useState<boolean>(false) // to show loader
  const [editMode, setEditMode] = useState<boolean>(true) // if all page should be disabled or not
  const [challengesList, setChallengesList] = useState<Challenge[]>([]) // list of all challenges
  const [doneChanges, setDoneChanges] = useState<boolean>(false) // if user has done changes
  const [currentChallenge, setCurrentChallenge] = useState<Challenge>(
    new Challenge()
  ) // current challenge
  const [preChangesCurrentChallenge, setPreChangesCurrentChallenge] =
    useState<Challenge>(new Challenge()) // fetched current challenge
  const [challengesListNextToken, setChallengesListNextToken] = useState<
    string | null
  >(null) // next token for challenges list
  const [hasSearched, setHasSearched] = useState<boolean>(false) // if user has searched or not
  const [currentUsersLeaderboard, setCurrentUsersLeaderboard] = useState<
    UsersLeaderboardItem[]
  >([]) // user leaderboard
  const [
    currentUsersLeaderboardNextToken,
    setCurrentUsersLeaderboardNextToken,
  ] = useState<string | null>(null) // user leaderboard next token
  const [currentGroupsLeaderboard, setCurrentGroupsLeaderboard] = useState<
    GroupsLeaderboardItem[]
  >([]) // groups leaderboard
  const [
    currentGroupsLeaderboardNextToken,
    setCurrentGroupsLeaderboardNextToken,
  ] = useState<string | null>(null) // groups leaderboard next token
  const [currentGroupLeaderboard, setCurrentGroupLeaderboard] = useState<
    UsersLeaderboardItem[]
  >([]) // group leaderboard
  const [
    currentGroupLeaderboardNextToken,
    setCurrentGroupLeaderboardNextToken,
  ] = useState<string | null>(null) // group leaderboard next token

  // search states
  const [searchMetric, setSearchMetric] = useState<AutocompleteOption | null>(
    null
  )
  const [searchTeam, setSearchTeam] = useState<AutocompleteOption | null>(null)
  const [searchTime, setSearchTime] = useState<AutocompleteOption | null>(null)
  const [searchTitle, setSearchTitle] = useState<string>("")

  // errors for challenge edit
  const [titleError, setTitleError] = useState<boolean>(false)
  const [metricError, setMetricError] = useState<boolean>(false)
  const [targetAmountError, setTargetAmountError] = useState<boolean>(false)
  const [personalTargetAmountError, setPersonalTargetAmountError] =
    useState<boolean>(false)
  const [personalTargetPointsError, setPersonalTargetPointsError] =
    useState<boolean>(false)
  const [teamError, setTeamError] = useState<boolean>(false)
  const [leaderboardMinMembersError, setLeaderboardMinMembersError] =
    useState<boolean>(false)
  const [privacyPolicyError, setPrivacyPolicyError] = useState<boolean>(false)
  const [startsAtError, setStartsAtError] = useState<boolean>(false)
  const [endsAtError, setEndsAtError] = useState<boolean>(false)
  const [translationsErrors, setTranslationsErrors] = useState<
    { lang: string; hasErrors: boolean }[]
  >([]) // errors array for translations
  const hasError: boolean =
    titleError ||
    metricError ||
    targetAmountError ||
    personalTargetAmountError ||
    personalTargetPointsError ||
    teamError ||
    leaderboardMinMembersError ||
    privacyPolicyError ||
    startsAtError ||
    endsAtError ||
    translationsErrors.filter((item) => item.hasErrors).length !== 0 // if there are errors or not

  // reset all errors
  const resetErrors = () => {
    setTitleError(false)
    setMetricError(false)
    setTargetAmountError(false)
    setPersonalTargetAmountError(false)
    setPersonalTargetPointsError(false)
    setTeamError(false)
    setLeaderboardMinMembersError(false)
    setPrivacyPolicyError(false)
    setStartsAtError(false)
    setEndsAtError(false)
    const newTranslationsErrors: { lang: string; hasErrors: boolean }[] = []
    currentChallenge.document.items.forEach((item) => {
      newTranslationsErrors.push({ lang: item.lang, hasErrors: false })
    })
    setTranslationsErrors(newTranslationsErrors)
  }

  // queries
  const [listChallengesQuery] = useLazyQuery(listChallenges)
  const [challengeQuery] = useLazyQuery(challenge)
  const [filterChallengesQuery] = useLazyQuery(searchChallenges)
  const [challengeUserLeaderboardListQuery] = useLazyQuery(
    challengeUserLeaderboardList
  )
  const [challengeGroupLeaderboardListQuery] = useLazyQuery(
    challengeGroupLeaderboardList
  )
  const [challengeIntraGroupLeaderboardListQuery] = useLazyQuery(
    challengeIntraGroupLeaderboardList
  )

  // mutations
  const [createChallengeMutation] = useMutation(createChallenge)
  const [upsertDocumentChallengeMutation] = useMutation(upsertDocumentChallenge)
  const [updateChallengeMutation] = useMutation(updateChallenge)
  const [deleteChallengeMutation] = useMutation(deleteSingleChallenge)
  const [challengeAutojoinMutation] = useMutation(challengeAutojoin)

  // get challenges list
  const getChallengesList = useCallback(
    async (withLoading: boolean = true) => {
      if (withLoading) {
        setLoading(true)
        panelStatus.filter((item) => item.name === "Challenges")[0].loading =
          true
        setPanelStatus([...panelStatus])
      }

      try {
        logger(Status.Api, "QUERY challengesList", listChallenges)
        const { data } = await listChallengesQuery({
          fetchPolicy: "no-cache",
        })
        logger(Status.Info, "challenges list", data.challengesList.items)

        setChallengesList(data.challengesList.items)
        setChallengesListNextToken(data.challengesList.nextToken)

        setHasSearched(false)
        setLoading(false)
        setUpdatingList(false)
        panelStatus.filter((item) => item.name === "Challenges")[0].status =
          "success"
        panelStatus.filter((item) => item.name === "Challenges")[0].loading =
          false
        setPanelStatus([...panelStatus])
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `challengesList`, e.message)
        }
        setLoading(false)
        setUpdatingList(false)
        panelStatus.filter((item) => item.name === "Challenges")[0].status =
          "error"
        panelStatus.filter((item) => item.name === "Challenges")[0].loading =
          false
        setPanelStatus([...panelStatus])
      }
    },
    [setError, setErrorMessage]
  )

  // load more challenges
  const loadMoreChallenges = async () => {
    try {
      logger(Status.Api, "QUERY challengesList", listChallenges)
      const { data } = await listChallengesQuery({
        variables: {
          input: { nextToken: challengesListNextToken },
        },
        fetchPolicy: "no-cache",
      })
      logger(Status.Info, "challenges list", [
        ...challengesList,
        ...data.challengesList.items,
      ])

      setChallengesList([...challengesList, ...data.challengesList.items])
      setChallengesListNextToken(data.challengesList.nextToken)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `challengesList`, e.message)
      }
      return false
    }
  }

  // search challenges
  const searchChallengesList = async (
    withLoading = true,
    withNextToken = false
  ) => {
    if (withLoading) {
      setUpdatingList(true)
    }

    try {
      const input: {
        text?: string
        metricId?: string
        teamId?: string
        timeFilter?: string
        nextToken?: string
      } = {
        text: searchTitle,
        metricId: searchMetric ? searchMetric.id : null,
        teamId: searchTeam ? searchTeam.id : null,
        timeFilter: searchTime ? searchTime.id : null,
      }

      if (hasSearched && withNextToken) {
        input.nextToken = challengesListNextToken
      }

      logger(Status.Api, "QUERY challengesSearch", searchChallenges)
      const { data } = await filterChallengesQuery({
        variables: {
          input: input,
        },
        fetchPolicy: "no-cache",
      })

      if (input.nextToken) {
        logger(Status.Info, "challenges list", [
          ...challengesList,
          ...data.challengesSearch.items,
        ])

        setChallengesList([...challengesList, ...data.challengesSearch.items])
        setChallengesListNextToken(data.challengesSearch.nextToken)
      } else {
        logger(Status.Info, "challenges list", data.challengesSearch.items)

        setChallengesList(data.challengesSearch.items)
        setChallengesListNextToken(data.challengesSearch.nextToken)
      }

      setHasSearched(true)
      setUpdatingList(false)
      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `challengesSearch`, e.message)
      }
      setUpdatingList(false)
      return false
    }
  }

  // get current challenge
  const getCurrentChallenge = useCallback(
    async (challengeId: string) => {
      try {
        logger(Status.Api, "QUERY challengeGet", challenge)
        const { data } = await challengeQuery({
          variables: { input: { id: challengeId } },
          fetchPolicy: "no-cache",
        })
        logger(Status.Info, `challenge ${challengeId}`, data.challengeGet)

        setCurrentChallenge(data.challengeGet)
        setPreChangesCurrentChallenge(deepCopy(data.challengeGet))
        data.challengeGet.document.items.forEach((item: ChallengesDocument) => {
          translationsErrors.push({ lang: item.lang, hasErrors: false })
        })
        setTranslationsErrors([...translationsErrors])

        return true
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `challengeGet`, e.message)
        }
        return false
      }
    },
    [setError, setErrorMessage]
  )

  // upsert challenge parent
  const upsertChallengeParent = async () => {
    try {
      const input: {
        id: string
        name: string
        startsAt: string
        endsAt: string
        sorting: number
        targetAmount: number
        metricId: string
        type: ChallengeType
        leaderboardGroupType: "average" | "sum"
        leaderboardMinMembers: number
        teamId?: string
        personalTargetAmount?: number
        personalTargetPoints?: number
        privacyPolicy?: string
        versionType?: ChallengeVersionType
      } = {
        id: currentChallenge.id,
        name: currentChallenge.name,
        startsAt: currentChallenge.startsAt,
        endsAt: currentChallenge.endsAt,
        sorting: currentChallenge.sorting,
        targetAmount: currentChallenge.targetAmount,
        metricId: currentChallenge.metric.id,
        type: currentChallenge.type,
        leaderboardGroupType: currentChallenge.leaderboardGroupType,
        leaderboardMinMembers: currentChallenge.leaderboardMinMembers,
        privacyPolicy: currentChallenge.privacyPolicy,
        versionType: currentChallenge.versionType,
      }

      if (
        currentChallenge.type !== ChallengeType.featured &&
        currentChallenge.team
      ) {
        input.teamId = currentChallenge.team.id as string
      }

      if (currentChallenge.personalTargetAmount) {
        input.personalTargetAmount = currentChallenge.personalTargetAmount
      } else {
        input.personalTargetAmount = null
      }

      if (currentChallenge.personalTargetPoints) {
        input.personalTargetPoints = currentChallenge.personalTargetPoints
      } else {
        input.personalTargetPoints = null
      }

      logger(Status.Api, "MUTATION challengeUpdate", updateChallenge)
      const { data } = await updateChallengeMutation({
        variables: { input: input },
      })
      logger(
        Status.Info,
        `challenge ${data.challengeUpdate.id} upserted`,
        data.challengeUpdate
      )

      const currentChallengeCopy: Challenge = deepCopy(currentChallenge)
      currentChallengeCopy.id = data.challengeUpdate.id
      currentChallengeCopy.uid = data.challengeUpdate.uid
      currentChallengeCopy.startsAt = data.challengeUpdate.startsAt
      currentChallengeCopy.endsAt = data.challengeUpdate.endsAt
      currentChallengeCopy.currentAmount = data.challengeUpdate.currentAmount
      currentChallengeCopy.targetAmount = data.challengeUpdate.targetAmount
      currentChallengeCopy.name = data.challengeUpdate.name
      currentChallengeCopy.type = data.challengeUpdate.type
      currentChallengeCopy.updatedAt = data.challengeUpdate.updatedAt
      currentChallengeCopy.metric = data.challengeUpdate.metric
      currentChallengeCopy.sorting = data.challengeUpdate.sorting
      currentChallengeCopy.team = data.challengeUpdate.team
      currentChallengeCopy.leaderboardGroupType =
        data.challengeUpdate.leaderboardGroupType
      currentChallengeCopy.leaderboardMinMembers =
        data.challengeUpdate.leaderboardMinMembers
      currentChallengeCopy.personalTargetAmount =
        data.challengeUpdate.personalTargetAmount
      currentChallengeCopy.personalTargetPoints =
        data.challengeUpdate.personalTargetPoints
      currentChallengeCopy.privacyPolicy = data.challengeUpdate.privacyPolicy
      currentChallengeCopy.document.parentId = data.challengeUpdate.id
      setCurrentChallenge(currentChallengeCopy)
      setPreChangesCurrentChallenge(deepCopy(currentChallengeCopy))

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `challengeUpdate`, e.message)
      }
      return false
    }
  }

  // upsert challenge document
  const upsertChallengeDocument = async () => {
    try {
      // parse data for inpout
      const currentChallengeCopy = deepCopy(currentChallenge)
      currentChallengeCopy.document.items.forEach((item: any) => {
        const newBody = []
        if (item.body) {
          item.body.forEach((bodyItem: any) => {
            const { sliceType, __typename, ...rest } = bodyItem
            newBody.push({
              [lowercaseFirstCharacter(bodyItem.sliceType)]: {
                ...rest,
              },
            })
          })
        }
        item.body = newBody

        const newPrizes = []
        if (item.prizes) {
          item.prizes.forEach((bodyItem: any) => {
            const { sliceType, __typename, ...rest } = bodyItem
            newPrizes.push({
              [lowercaseFirstCharacter(bodyItem.sliceType)]: {
                ...rest,
              },
            })
          })
        }
        item.prizes = newPrizes

        item.default = item.isDefault
        delete item.isDefault
        delete item.type
        delete item.updatedAt
        delete item.parentId
        delete item.__typename
      })

      const input = {
        challengeDocumentItems: currentChallengeCopy.document.items,
        parentId: currentChallengeCopy.document.parentId,
        type: "Challenge",
      }

      logger(Status.Api, "MUTATION documentUpsert", upsertDocumentChallenge)
      const { data } = await upsertDocumentChallengeMutation({
        variables: { input: input },
      })
      logger(
        Status.Info,
        `challenge ${data.documentUpsert.parentId} document upserted`,
        data.documentUpsert
      )

      const currentChallengeSecondCopy: Challenge = deepCopy(currentChallenge)
      currentChallengeSecondCopy.document = data.documentUpsert
      setCurrentChallenge(currentChallengeSecondCopy)
      setPreChangesCurrentChallenge(deepCopy(currentChallengeSecondCopy))

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `documentUpsert`, e.message)
      }
      return false
    }
  }

  // create challenge parent
  const createChallengeParent = async (input: {
    endsAt: string
    metricId: string
    name: string
    startsAt: string
    targetAmount: number
    leaderboardGroupType: "average" | "sum"
    leaderboardMinMembers: number
    type: ChallengeType
    uid: string
    teamId?: string
    personalTargetAmount?: number
    personalTargetPoints?: number
    privacyPolicy?: string
    versionType?: ChallengeVersionType
  }) => {
    try {
      logger(Status.Api, "MUTATION challengeCreate", createChallenge)
      const { data } = await createChallengeMutation({
        variables: { input: input },
      })
      logger(Status.Info, `challenge created`, data.challengeCreate)

      return data.challengeCreate
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `challengeCreate`, e.message)
      }
      return false
    }
  }

  // create challenge document
  const createChallengeDocument = async (input: {
    parentId: string
    type: string
    challengeDocumentItems: {
      lang: string
      title: string
      image: string
      default: boolean
      body: object[]
      prizes: object[]
      sponsor?: string
      subtitle?: string
    }[]
  }) => {
    try {
      logger(Status.Api, "MUTATION documentUpsert", upsertDocumentChallenge)
      const { data } = await upsertDocumentChallengeMutation({
        variables: { input: input },
      })
      logger(Status.Info, `document created`, data.documentUpsert)

      return data.documentUpsert
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `documentUpsert`, e.message)
      }
      return false
    }
  }

  // autojoin team
  const autojoinTeam = async () => {
    try {
      logger(Status.Api, "MUTATION challengeAutojoin", challengeAutojoin)
      const { data } = await challengeAutojoinMutation({
        variables: { input: { challengeId: preChangesCurrentChallenge.id } },
      })
      logger(Status.Info, `team autojoined`)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `challengeAutojoin`, e.message)
      }
      return false
    }
  }

  // delete challenge
  const deleteChallenge = async (challengeToDeleteId: string) => {
    try {
      logger(Status.Api, "MUTATION challengeDelete", deleteSingleChallenge)
      const { data } = await deleteChallengeMutation({
        variables: { input: { id: challengeToDeleteId } },
      })
      logger(Status.Info, `challenge ${data.challengeDelete.id} deleted`)

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `challengeDelete`, e.message)
      }
      return false
    }
  }

  // add translation
  const addTranslation = (translationToAdd: string) => {
    const documentToAdd: ChallengesDocument = {
      body: [],
      isDefault: false,
      lang: translationToAdd,
      title: "Title",
      type: "Challenge",
    }

    translationsErrors.push({
      lang: translationToAdd,
      hasErrors: false,
    })
    setTranslationsErrors([...translationsErrors])

    currentChallenge.document.items.push(documentToAdd)
    setCurrentChallenge({ ...currentChallenge })
  }

  // copy details from default translation
  const copyDetailsFromDefault = (itemToCopyToIndex: number) => {
    const defaultItem = currentChallenge.document.items.filter(
      (item) => item.isDefault
    )[0]

    currentChallenge.document.items[itemToCopyToIndex].title = defaultItem.title
    currentChallenge.document.items[itemToCopyToIndex].subtitle =
      defaultItem.subtitle
    currentChallenge.document.items[itemToCopyToIndex].sponsor =
      defaultItem.sponsor
    currentChallenge.document.items[itemToCopyToIndex].image = defaultItem.image

    setCurrentChallenge({ ...currentChallenge })
  }

  // copy body from default translation
  const copyBodyFromDefault = (itemToCopyToIndex: number) => {
    const defaultItem = currentChallenge.document.items.find(
      (item) => item.isDefault
    )

    currentChallenge.document.items[itemToCopyToIndex].body = deepCopy(
      defaultItem.body
    )

    setCurrentChallenge({ ...currentChallenge })
  }

  // copy prizes from default translation
  const copyPrizesFromDefault = (itemToCopyToIndex: number) => {
    const defaultItem = currentChallenge.document.items.find(
      (item) => item.isDefault
    )

    currentChallenge.document.items[itemToCopyToIndex].prizes = deepCopy(
      defaultItem.prizes
    )

    setCurrentChallenge({ ...currentChallenge })
  }

  // cancel changes
  const cancelChanges = () => {
    setCurrentChallenge(deepCopy(preChangesCurrentChallenge))
    resetErrors()
  }

  // check if user has done changes
  useEffect(() => {
    if (
      JSON.stringify(currentChallenge) !==
      JSON.stringify(preChangesCurrentChallenge)
    ) {
      setDoneChanges(true)
    } else {
      setDoneChanges(false)
    }
  }, [currentChallenge])

  // reset list filters
  const resetFilters = () => {
    setSearchMetric(null)
    setSearchTeam(null)
    setSearchTime(null)
    setSearchTitle("")
    getChallengesList()
  }

  // add update function to panel status
  useEffect(() => {
    panelStatus.filter((item) => item.name === "Challenges")[0].updateFunction =
      getChallengesList
  }, [])

  // get user leaderboard
  const getUsersLeaderboard = async (
    challengeId: string,
    withNextToken = true
  ) => {
    try {
      logger(
        Status.Api,
        "QUERY challengeUserLeaderboardList",
        challengeUserLeaderboardList
      )
      const { data } = await challengeUserLeaderboardListQuery({
        fetchPolicy: "no-cache",
        variables: {
          input: {
            challengeId: challengeId,
            nextToken: withNextToken ? currentUsersLeaderboardNextToken : null,
          },
        },
      })
      logger(
        Status.Info,
        `challenge ${challengeId} users leaderboard`,
        data.challengeUserLeaderboardList.items
      )

      if (currentUsersLeaderboardNextToken && withNextToken) {
        setCurrentUsersLeaderboard([
          ...currentUsersLeaderboard,
          ...data.challengeUserLeaderboardList.items,
        ])
      } else {
        setCurrentUsersLeaderboard(data.challengeUserLeaderboardList.items)
      }
      setCurrentUsersLeaderboardNextToken(
        data.challengeUserLeaderboardList.nextToken
      )

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `challengeUserLeaderboardList`, e.message)
      }
      return false
    }
  }

  // get groups leaderboard
  const getGroupsLeaderboard = async (
    challengeId: string,
    leaderboardGroupType: "sum" | "average",
    withNextToken = true
  ) => {
    try {
      logger(
        Status.Api,
        "QUERY challengeGroupLeaderboardList",
        challengeGroupLeaderboardList
      )
      const { data } = await challengeGroupLeaderboardListQuery({
        fetchPolicy: "no-cache",
        variables: {
          input: {
            challengeId: challengeId,
            leaderboardGroupType: leaderboardGroupType,
            nextToken: withNextToken ? currentGroupsLeaderboardNextToken : null,
          },
        },
      })
      logger(
        Status.Info,
        `challenge ${challengeId} groups leaderboard`,
        data.challengeGroupLeaderboardList.items
      )

      if (currentGroupsLeaderboardNextToken && withNextToken) {
        setCurrentGroupsLeaderboard([
          ...currentGroupsLeaderboard,
          ...data.challengeGroupLeaderboardList.items,
        ])
      } else {
        setCurrentGroupsLeaderboard(data.challengeGroupLeaderboardList.items)
      }
      setCurrentGroupsLeaderboardNextToken(
        data.challengeGroupLeaderboardList.nextToken
      )

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `challengeGroupLeaderboardList`, e.message)
      }
      return false
    }
  }

  // get single group leaderboard
  const getGroupLeaderboard = async (
    groupId: string,
    challengeId: string,
    withNextToken = true
  ) => {
    try {
      logger(
        Status.Api,
        "QUERY challengeIntraGroupLeaderboardList",
        challengeIntraGroupLeaderboardList
      )
      const { data } = await challengeIntraGroupLeaderboardListQuery({
        fetchPolicy: "no-cache",
        variables: {
          input: {
            groupId: groupId,
            challengeId: challengeId,
            nextToken: withNextToken ? currentGroupLeaderboardNextToken : null,
          },
        },
      })
      logger(
        Status.Info,
        `group ${groupId} leaderboard`,
        data.challengeIntraGroupLeaderboardList.items
      )

      if (currentGroupLeaderboardNextToken && withNextToken) {
        setCurrentGroupLeaderboard([
          ...currentGroupLeaderboard,
          ...data.challengeIntraGroupLeaderboardList.items,
        ])
      } else {
        setCurrentGroupLeaderboard(
          data.challengeIntraGroupLeaderboardList.items
        )
      }
      setCurrentGroupLeaderboardNextToken(
        data.challengeIntraGroupLeaderboardList.nextToken
      )

      return true
    } catch (e: unknown) {
      if (e instanceof Error) {
        setError(true)
        setErrorMessage(e.message)
        logger(Status.Error, `challengeIntraGroupLeaderboardList`, e.message)
      }
      return false
    }
  }

  // get all users or groups leaderboard for csv download
  const getAllLeaderboard = async (
    challengeId: string,
    withEmails: boolean,
    leaderboardType: "users" | "groups" | "group",
    groupId?: string,
    leaderboardGroupType?: "sum" | "average"
  ) => {
    if (leaderboardType === "users") {
      try {
        let nextToken: string = null
        let leaderboard = []
        do {
          logger(
            Status.Api,
            "QUERY challengeUserLeaderboardList",
            challengeUserLeaderboardList
          )
          const { data } = await challengeUserLeaderboardListQuery({
            fetchPolicy: "no-cache",
            variables: {
              input: {
                challengeId: challengeId,
                nextToken: nextToken,
              },
            },
          })
          leaderboard.push(...data.challengeUserLeaderboardList.items)
          nextToken = data.challengeUserLeaderboardList.nextToken
        } while (nextToken !== null)

        let data = [
          withEmails
            ? ["rank", "first name", "last name", "uid", "email", "score"]
            : ["rank", "first name", "last name", "uid", "score"],
        ]
        for (let i = 0; i < leaderboard.length; i++) {
          if (withEmails) {
            data.push([
              (i + 1).toString(),
              leaderboard[i].user.first_name,
              leaderboard[i].user.last_name,
              leaderboard[i].user.uid,
              leaderboard[i].user.email,
              (
                Math.round((leaderboard[i].metricSum + Number.EPSILON) * 10) /
                10
              ).toString(),
            ])
          } else {
            data.push([
              (i + 1).toString(),
              leaderboard[i].user.first_name,
              leaderboard[i].user.last_name,
              leaderboard[i].user.uid,
              (
                Math.round((leaderboard[i].metricSum + Number.EPSILON) * 10) /
                10
              ).toString(),
            ])
          }
        }
        logger(
          Status.Info,
          `challenge ${challengeId} users leaderboard CSV data`,
          data
        )

        return data
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `challengeUserLeaderboardList`, e.message)
        }
        return []
      }
    } else if (leaderboardType === "groups") {
      try {
        let nextToken: string = null
        let leaderboard = []
        do {
          logger(
            Status.Api,
            "QUERY challengeGroupLeaderboardList",
            challengeGroupLeaderboardList
          )
          const { data } = await challengeGroupLeaderboardListQuery({
            fetchPolicy: "no-cache",
            variables: {
              input: {
                challengeId: challengeId,
                leaderboardGroupType: leaderboardGroupType,
                nextToken: nextToken,
              },
            },
          })
          leaderboard.push(...data.challengeGroupLeaderboardList.items)
          nextToken = data.challengeGroupLeaderboardList.nextToken
        } while (nextToken !== null)

        let data = [["rank", "name", "id", `score by ${leaderboardGroupType}`]]
        for (let i = 0; i < leaderboard.length; i++) {
          data.push([
            (i + 1).toString(),
            preChangesCurrentChallenge.team.groups.filter(
              (item) => item.groupId === leaderboard[i].groupId
            )[0].name,
            leaderboard[i].groupId,
            leaderboard[i].metricSum
              ? (
                  Math.round((leaderboard[i].metricSum + Number.EPSILON) * 10) /
                  10
                ).toString()
              : (
                  Math.round(
                    (leaderboard[i].metricAverage + Number.EPSILON) * 10
                  ) / 10
                ).toString(),
          ])
        }
        logger(
          Status.Info,
          `challenge ${challengeId} groups leaderboard CSV data`,
          data
        )

        return data
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `challengeGroupLeaderboardList`, e.message)
        }
        return []
      }
    } else {
      try {
        let nextToken: string = null
        let leaderboard = []
        do {
          logger(
            Status.Api,
            "QUERY challengeIntraGroupLeaderboardList",
            challengeIntraGroupLeaderboardList
          )
          const { data } = await challengeIntraGroupLeaderboardListQuery({
            fetchPolicy: "no-cache",
            variables: {
              input: {
                groupId: groupId,
                challengeId: challengeId,
                nextToken: nextToken,
              },
            },
          })
          leaderboard.push(...data.challengeIntraGroupLeaderboardList.items)
          nextToken = data.challengeIntraGroupLeaderboardList.nextToken
        } while (nextToken !== null)

        let data = [["rank", "first name", "last name", "uid", "score"]]
        for (let i = 0; i < leaderboard.length; i++) {
          data.push([
            (i + 1).toString(),
            leaderboard[i].user.first_name,
            leaderboard[i].user.last_name,
            leaderboard[i].user.uid,
            (
              Math.round((leaderboard[i].metricSum + Number.EPSILON) * 10) / 10
            ).toString(),
          ])
        }
        logger(Status.Info, `group ${groupId} leaderboard CSV data`, data)

        return data
      } catch (e: unknown) {
        if (e instanceof Error) {
          setError(true)
          setErrorMessage(e.message)
          logger(Status.Error, `challengeIntraGroupLeaderboardList`, e.message)
        }
        return []
      }
    }
  }

  return (
    <ChallengesContext.Provider
      value={{
        loading,
        setLoading,
        updatingList,
        setUpdatingList,
        editMode,
        setEditMode,
        challengesList,
        getChallengesList,
        doneChanges,
        cancelChanges,
        currentChallenge,
        setCurrentChallenge,
        getCurrentChallenge,
        preChangesCurrentChallenge,
        addTranslation,
        upsertChallengeParent,
        hasError,
        translationsErrors,
        setTranslationsErrors,
        upsertChallengeDocument,
        copyDetailsFromDefault,
        copyBodyFromDefault,
        copyPrizesFromDefault,
        createChallengeParent,
        createChallengeDocument,
        challengesListNextToken,
        loadMoreChallenges,
        hasSearched,
        setHasSearched,
        searchMetric,
        setSearchMetric,
        searchTeam,
        setSearchTeam,
        searchTime,
        setSearchTime,
        searchTitle,
        setSearchTitle,
        searchChallengesList,
        resetFilters,
        startsAtError,
        setStartsAtError,
        endsAtError,
        setEndsAtError,
        titleError,
        setTitleError,
        metricError,
        setMetricError,
        targetAmountError,
        setTargetAmountError,
        personalTargetAmountError,
        setPersonalTargetAmountError,
        personalTargetPointsError,
        setPersonalTargetPointsError,
        teamError,
        setTeamError,
        leaderboardMinMembersError,
        setLeaderboardMinMembersError,
        privacyPolicyError,
        setPrivacyPolicyError,
        deleteChallenge,
        autojoinTeam,
        currentUsersLeaderboard,
        setCurrentUsersLeaderboard,
        currentUsersLeaderboardNextToken,
        setCurrentUsersLeaderboardNextToken,
        getUsersLeaderboard,
        currentGroupsLeaderboard,
        setCurrentGroupsLeaderboard,
        currentGroupsLeaderboardNextToken,
        setCurrentGroupsLeaderboardNextToken,
        getGroupsLeaderboard,
        currentGroupLeaderboard,
        setCurrentGroupLeaderboard,
        currentGroupLeaderboardNextToken,
        setCurrentGroupLeaderboardNextToken,
        getGroupLeaderboard,
        getAllLeaderboard,
      }}
    >
      {children}
    </ChallengesContext.Provider>
  )
}

export { ChallengesController, ChallengesContext }
