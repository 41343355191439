import { Button, Stack } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { MediaContext } from "../../controllers/media"
import { MediaSize } from "../../services/config/enum"
import "../../styles/imagePicker.scss"
import PickImageDialog from "../episode/pickImageDialog"

const ImagePicker = ({
  title,
  pickedImage,
  setPickedImage,
  width,
  disabled = false,
  height = 96,
  removeButton = false,
  filters,
  error = false,
  disableCategoryFilter = false,
  placeholderHeight,
  placeholderWidth,
}: {
  title: string
  pickedImage: string
  setPickedImage: (selectedImage: {
    url: string
    id: string
    description?: string
    type?: MediaSize
  }) => void
  width?: string
  disabled?: boolean
  height?: number
  removeButton?: boolean
  filters?: { category?: string; size?: MediaSize[] }
  error?: boolean
  disableCategoryFilter?: boolean
  placeholderHeight?: number
  placeholderWidth?: string
}) => {
  const { setMediaList } = useContext(MediaContext)

  // pick image dialog open
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)

  useEffect(() => {
    if (dialogOpen) {
      setMediaList([])
    }
  }, [dialogOpen])

  // selected image from media list
  const [selectedImage, setSelectedImage] = useState<{
    id: string
    url: string
    description?: string
  } | null>(null)

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: width ? width : null,
      }}
    >
      <div
        className={
          disabled
            ? "image-picker-container-disabled"
            : error
            ? "image-picker-container-error-prismic"
            : "image-picker-container"
        }
        style={{
          borderRadius: 8,
          height: height,
          color: "#666666",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          padding: 15,
          width: "100%",
        }}
        onClick={() => {
          if (!disabled && !dialogOpen) {
            setDialogOpen(true)
          }
        }}
      >
        <div
          style={{
            position: "absolute",
            top: -10,
            left: 8,
            color: error ? "#d3302f" : disabled ? "#9e9e9f" : "#939EAA",
            fontSize: 12,
            backgroundColor: "white",
            paddingLeft: 5,
            paddingRight: 5,
            cursor: "default",
          }}
        >
          {title}
        </div>
        {!pickedImage.length ? (
          <div
            style={{
              height: placeholderHeight ? placeholderHeight : height - 18,
              width: placeholderWidth ? placeholderWidth : "28%",
              border: disabled
                ? "1px dashed #c4c4c4"
                : error
                ? "1px dashed " + "#d3302f"
                : "1px dashed #c4c4c4",
              borderRadius: 5,
            }}
          />
        ) : (
          <img
            style={{
              maxHeight: height - 18,
              maxWidth: "42%",
              opacity: disabled ? 0.8 : 1,
              transition: "150ms",
            }}
            src={pickedImage}
          />
        )}
        <Stack style={{ position: "absolute", right: 26, bottom: 0 }}>
          {pickedImage.length ? (
            removeButton ? (
              <Stack>
                <Button
                  disabled={disabled}
                  onClick={(e: any) => {
                    e.preventDefault()
                    e.stopPropagation()
                    setPickedImage({ id: "", url: "", type: null })
                  }}
                >
                  Remove
                </Button>
                <Button
                  disabled={disabled}
                  onClick={() => {
                    setDialogOpen(true)
                  }}
                >
                  Change
                </Button>
              </Stack>
            ) : (
              <Button
                disabled={disabled}
                onClick={() => {
                  setDialogOpen(true)
                }}
              >
                Change
              </Button>
            )
          ) : (
            <Button
              disabled={disabled}
              onClick={() => {
                setDialogOpen(true)
              }}
            >
              Select
            </Button>
          )}
        </Stack>
        <PickImageDialog
          open={dialogOpen}
          setOpen={setDialogOpen}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          setPickedImage={setPickedImage}
          filters={filters}
          disableCategoryFilter={disableCategoryFilter}
        />
      </div>
      {error && (
        <p
          style={{
            color: "#d3302f",
            fontSize: "0.75rem",
            lineHeight: 1.66,
            marginLeft: 14,
            marginTop: 4,
          }}
        >
          You must add an image
        </p>
      )}
    </div>
  )
}

export default ImagePicker
